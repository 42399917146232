.video-error-cover {
  align-items: center;
  background: $base-overlay-background;
  color: $primary-text-color;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-top: 8px;
  position: relative;
  text-align: center;
  z-index: 100;
}

.media-spoiler {
  background: $base-overlay-background;
  color: $darker-text-color;
  border: 0;
  width: 100%;
  height: 100%;

  &:hover,
  &:active,
  &:focus {
    color: lighten($darker-text-color, 8%);
  }

  .status__content > & {
    margin-top: 15px; // Add margin when used bare for NSFW video player
  }
  @include fullwidth-gallery;
}

.media-spoiler__warning {
  display: block;
  font-size: 14px;
}

.media-spoiler__trigger {
  display: block;
  font-size: 11px;
  font-weight: 500;
}

.media-gallery__gifv__label {
  display: block;
  position: absolute;
  color: $primary-text-color;
  background: rgba($base-overlay-background, 0.5);
  bottom: 6px;
  inset-inline-start: 6px;
  padding: 2px 6px;
  border-radius: 2px;
  font-size: 11px;
  font-weight: 600;
  z-index: 1;
  pointer-events: none;
  opacity: 0.9;
  transition: opacity 0.1s ease;
  line-height: 18px;
}

.media-gallery__gifv {
  &:hover {
    .media-gallery__gifv__label {
      opacity: 1;
    }
  }
}

.media-gallery {
  box-sizing: border-box;
  margin-top: 8px;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  width: 100%;
  min-height: 64px;

  @include fullwidth-gallery;
}

.media-gallery__item {
  border: 0;
  box-sizing: border-box;
  display: block;
  float: left;
  position: relative;
  border-radius: 4px;
  overflow: hidden;

  .full-width & {
    border-radius: 0;
  }

  &.standalone {
    .media-gallery__item-gifv-thumbnail {
      transform: none;
      top: 0;
    }
  }

  &.letterbox {
    background: $base-shadow-color;
  }
}

.media-gallery__item-thumbnail {
  cursor: zoom-in;
  display: block;
  text-decoration: none;
  color: $secondary-text-color;
  position: relative;
  z-index: 1;

  &,
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;

    &:not(.letterbox) {
      height: 100%;
      object-fit: cover;
    }
  }
}

.media-gallery__preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  z-index: 0;
  background: $base-overlay-background;

  &--hidden {
    display: none;
  }
}

.media-gallery__gifv {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.media-gallery__item-gifv-thumbnail {
  cursor: zoom-in;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
  object-fit: contain;
  user-select: none;

  &:not(.letterbox) {
    height: 100%;
    object-fit: cover;
  }
}

.media-gallery__item-thumbnail-label {
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  position: absolute;
}

.video-modal__container {
  max-width: 100vw;
  max-height: 100vh;
}

.audio-modal__container {
  width: 50vw;
}

.media-modal {
  width: 100%;
  height: 100%;
  position: relative;

  &__close,
  &__zoom-button {
    color: rgba($white, 0.7);

    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: rgba($white, 0.15);
    }

    &:focus {
      background-color: rgba($white, 0.3);
    }
  }
}

.media-modal__closer {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  bottom: 0;
}

.media-modal__navigation {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  bottom: 0;
  pointer-events: none;
  transition: opacity 0.3s linear;
  will-change: opacity;

  * {
    pointer-events: auto;
  }

  &.media-modal__navigation--hidden {
    opacity: 0;

    * {
      pointer-events: none;
    }
  }
}

.media-modal__nav {
  background: transparent;
  box-sizing: border-box;
  border: 0;
  color: rgba($primary-text-color, 0.7);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 24px;
  height: 20vmax;
  margin: auto 0;
  padding: 30px 15px;
  position: absolute;
  top: 0;
  bottom: 0;

  &:hover,
  &:focus,
  &:active {
    color: $primary-text-color;
  }
}

.media-modal__nav--left {
  inset-inline-start: 0;
}

.media-modal__nav--right {
  inset-inline-end: 0;
}

.media-modal__overlay {
  max-width: 600px;
  position: absolute;
  inset-inline-start: 0;
  inset-inline-end: 0;
  bottom: 0;
  margin: 0 auto;

  .picture-in-picture__footer {
    border-radius: 0;
    background: transparent;
    padding: 20px 0;

    .icon-button {
      color: $white;

      &:hover,
      &:focus,
      &:active {
        color: $white;
        background-color: rgba($white, 0.15);
      }

      &:focus {
        background-color: rgba($white, 0.3);
      }

      &.active {
        color: $highlight-text-color;

        &:hover,
        &:focus,
        &:active {
          background: rgba($highlight-text-color, 0.15);
        }

        &:focus {
          background: rgba($highlight-text-color, 0.3);
        }
      }

      &.star-icon.active {
        color: $gold-star;

        &:hover,
        &:focus,
        &:active {
          background: rgba($gold-star, 0.15);
        }

        &:focus {
          background: rgba($gold-star, 0.3);
        }
      }

      &.disabled {
        color: $white;
        background-color: transparent;
        cursor: default;
        opacity: 0.4;
      }
    }
  }
}

.media-modal__pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.media-modal__page-dot {
  flex: 0 0 auto;
  background-color: $white;
  opacity: 0.4;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin: 0 4px;
  padding: 0;
  border: 0;
  font-size: 0;
  transition: opacity 0.2s ease-in-out;

  &.active {
    opacity: 1;
  }
}

.media-modal__close {
  position: absolute;
  inset-inline-end: 8px;
  top: 8px;
  z-index: 100;
}

.detailed,
.fullscreen {
  .video-player__volume__current,
  .video-player__volume::before {
    bottom: 27px;
  }

  .video-player__volume__handle {
    bottom: 23px;
  }
}

.audio-player {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  background: darken($ui-base-color, 8%);
  border-radius: 4px;
  padding-bottom: 44px;

  &.editable {
    border-radius: 0;
    height: 100%;
  }

  &.inactive {
    audio,
    .video-player__controls {
      visibility: hidden;
    }
  }

  .video-player__volume::before,
  .video-player__seek::before {
    background: currentColor;
    opacity: 0.15;
  }

  .video-player__seek__buffer {
    background: currentColor;
    opacity: 0.2;
  }

  .video-player__buttons button,
  .video-player__buttons a {
    color: currentColor;
    opacity: 0.75;

    &:active,
    &:hover,
    &:focus {
      color: currentColor;
      opacity: 1;
    }
  }

  .video-player__time-sep,
  .video-player__time-total,
  .video-player__time-current {
    color: currentColor;
  }

  .video-player__seek::before,
  .video-player__seek__buffer,
  .video-player__seek__progress {
    top: 0;
  }

  .video-player__seek__handle {
    top: -4px;
  }

  .video-player__controls {
    padding-top: 10px;
    background: transparent;
  }
}

.video-player {
  overflow: hidden;
  position: relative;
  background: $base-shadow-color;
  max-width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  color: $white;

  &.editable {
    border-radius: 0;
    height: 100% !important;
  }

  &:focus {
    outline: 0;
  }

  .detailed-status & {
    width: 100%;
    height: 100%;
  }

  @include fullwidth-gallery;

  video {
    display: block;
    max-width: 100vw;
    max-height: 80vh;
    z-index: 1;
    position: relative;
  }

  &.fullscreen {
    width: 100% !important;
    height: 100% !important;
    margin: 0;

    video {
      max-width: 100% !important;
      max-height: 100% !important;
      width: 100% !important;
      height: 100% !important;
      outline: 0;
    }
  }

  &.inline {
    video {
      object-fit: contain;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__controls {
    position: absolute;
    direction: ltr;
    z-index: 2;
    bottom: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    box-sizing: border-box;
    background: linear-gradient(
      0deg,
      rgba($base-shadow-color, 0.85) 0,
      rgba($base-shadow-color, 0.45) 60%,
      transparent
    );
    padding: 0 15px;
    opacity: 0;
    transition: opacity 0.1s ease;

    &.active {
      opacity: 1;
    }
  }

  &.inactive {
    video,
    .video-player__controls {
      visibility: hidden;
    }
  }

  &__spoiler {
    display: none;
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    border: 0;
    background: $base-shadow-color;
    color: $darker-text-color;
    transition: none;
    pointer-events: none;

    &.active {
      display: block;
      pointer-events: auto;

      &:hover,
      &:active,
      &:focus {
        color: lighten($darker-text-color, 7%);
      }
    }

    &__title {
      display: block;
      font-size: 14px;
    }

    &__subtitle {
      display: block;
      font-size: 11px;
      font-weight: 500;
    }
  }

  &__buttons-bar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    margin: 0 -5px;

    .video-player__download__icon {
      color: inherit;

      .fa,
      &:active .fa,
      &:hover .fa,
      &:focus .fa {
        color: inherit;
      }
    }
  }

  &__buttons {
    display: flex;
    flex: 0 1 auto;
    min-width: 30px;
    align-items: center;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .player-button {
      display: inline-block;
      outline: 0;
      flex: 0 0 auto;
      background: transparent;
      padding: 5px;
      font-size: 16px;
      border: 0;
      color: rgba($white, 0.75);

      &:active,
      &:hover,
      &:focus {
        color: $white;
      }
    }
  }

  &__time {
    display: inline;
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 5px;
  }

  &__time-sep,
  &__time-total,
  &__time-current {
    font-size: 14px;
    font-weight: 500;
  }

  &__time-current {
    color: $white;
  }

  &__time-sep {
    display: inline-block;
    margin: 0 6px;
  }

  &__time-sep,
  &__time-total {
    color: $white;
  }

  &__volume {
    flex: 0 0 auto;
    display: inline-flex;
    cursor: pointer;
    height: 24px;
    position: relative;
    overflow: hidden;

    .no-reduce-motion & {
      transition: all 100ms linear;
    }

    &.active {
      overflow: visible;
      width: 50px;
      margin-inline-end: 16px;
    }

    &::before {
      content: '';
      width: 50px;
      background: rgba($white, 0.35);
      border-radius: 4px;
      display: block;
      position: absolute;
      height: 4px;
      inset-inline-start: 0;
      top: 50%;
      transform: translate(0, -50%);
    }

    &__current {
      display: block;
      position: absolute;
      height: 4px;
      border-radius: 4px;
      inset-inline-start: 0;
      top: 50%;
      transform: translate(0, -50%);
      background: lighten($ui-highlight-color, 8%);
    }

    &__handle {
      position: absolute;
      z-index: 3;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      top: 50%;
      inset-inline-start: 0;
      margin-inline-start: -6px;
      transform: translate(0, -50%);
      background: lighten($ui-highlight-color, 8%);
      box-shadow: 1px 2px 6px rgba($base-shadow-color, 0.2);
      opacity: 0;

      .no-reduce-motion & {
        transition: opacity 100ms linear;
      }
    }

    &.active &__handle {
      opacity: 1;
    }
  }

  &__link {
    padding: 2px 10px;

    a {
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;
      color: $white;

      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &__seek {
    cursor: pointer;
    height: 24px;
    position: relative;

    &::before {
      content: '';
      width: 100%;
      background: rgba($white, 0.35);
      border-radius: 4px;
      display: block;
      position: absolute;
      height: 4px;
      top: 14px;
    }

    &__progress,
    &__buffer {
      display: block;
      position: absolute;
      height: 4px;
      border-radius: 4px;
      top: 14px;
      background: lighten($ui-highlight-color, 8%);
    }

    &__buffer {
      background: rgba($white, 0.2);
    }

    &__handle {
      position: absolute;
      z-index: 3;
      opacity: 0;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      top: 10px;
      margin-inline-start: -6px;
      background: lighten($ui-highlight-color, 8%);
      box-shadow: 1px 2px 6px rgba($base-shadow-color, 0.2);

      .no-reduce-motion & {
        transition: opacity 0.1s ease;
      }

      &.active {
        opacity: 1;
      }
    }

    &:hover {
      .video-player__seek__handle {
        opacity: 1;
      }
    }
  }

  &.detailed,
  &.fullscreen {
    .video-player__buttons {
      .player-button {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

.gifv {
  video {
    max-width: 100vw;
    max-height: 80vh;
  }
}
